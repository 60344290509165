
import Vue from "vue"

export default Vue.extend({
  props: {
    event: { type: String, required: true },
  },

  computed: {
    iframeSrc (): string {
      const base = "https://broadcast.comdi.com/player"
      return `${base}/${this.event}?lang=${this.$i18n.locale}`
    },
  },

  mounted () {
    window.addEventListener("message", this.handleComdiMessage)
  },
  beforeDestroy () {
    window.removeEventListener("message", this.handleComdiMessage)
  },

  methods: {
    async handleComdiMessage (event: any) {
      let { data } = event
      if (!data) return
      try { data = JSON.parse(data) } catch { return }
      if (!data) return

      const { currentEpisode } = data
      if (currentEpisode !== undefined)
        if (currentEpisode === null) {
          // this.isBreak_ = true
        } else {
          const code = currentEpisode.key
          if (code && code !== this.event) {
            const [res, err] = await this.$api.program.getSessionByCode(code)
            if (err)
              console.error(err)
            else if (res)
              this.$emit("change-session", res)
            // this.isBreak_ = false
          }
        }

      // const time = data.currentTime?.absoluteTime ?? 0
      // if (time) this.isBreak_ = false
    },
  },
})
